const appConfig = {
  apiPrefix: "/api",
  authenticatedEntryPath: "/app/client/welcome",
  mainEntryPath: "/app/client/dashboard",
  unAuthenticatedEntryPath: "/client/sign-in",
  tourPath: "/client/sign-in",
  signInPath: "/client/sign-in",
  signUpPath: "/client/sign-up",
  enableMock: true,
  appName: "TrainerX",
  pagePerData: 10,
};

export default appConfig;
