import { useSelector, useDispatch } from "react-redux";
import { setUser, userLoggedOut } from "store/auth/userSlice";
import { clearSettingData } from "store/settings/settingsSlice";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { useNavigate } from "react-router-dom";
import axiosInstance from "apiServices/axiosInstance";
import { setThemeColor, setThemeColorLevel } from "store/theme/themeSlice";
import { themeConfig } from "configs/theme.config";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { token, expired } = useSelector((state) => state?.auth?.session);
  const onThemeColorChange = (value) => {
    dispatch(setThemeColor(value));
  };

  const onThemeColorLevelChange = (value) => {
    dispatch(setThemeColorLevel(value));
  };
  const signIn = async ({ mobile_no }) => {
    try {
      let formData = new FormData();
      formData.append("phone_no", mobile_no);
      const response = await axiosInstance.post(
        "client/auth/client-sign-in",
        formData
      );

      if (response?.status) {
        const { token, data, gymProfile } = response;

        dispatch(onSignInSuccess(token));
        data &&
          dispatch(
            setUser({
              avatar: data?.avatar
                ? data?.avatar
                : "https://static.vecteezy.com/system/resources/thumbnails/002/002/403/small/man-with-beard-avatar-character-isolated-icon-free-vector.jpg",
              userName: data?.client_name ? data?.client_name : "Guest",
              email: data?.client_email ? data?.client_email : "demo@gmail.com",
              phone_no: data?.phone_no ? data?.phone_no : "+910000000000",
              authority: gymProfile.gym_profile_status
                ? data?.role
                  ? [data?.role.toLowerCase()]
                  : ["client"]
                : ["welcome"],
              userId: data?.client_id ? data?.client_id : 0,
              gym_logo: gymProfile.gym_logo,
              gymId: data?.gym_id ? data?.gym_id : 0,
            })
          );
        onThemeColorChange(
          gymProfile?.theme[0] ? gymProfile.theme[0] : themeConfig.themeColor
        );
        onThemeColorLevelChange(
          gymProfile?.theme[1]
            ? gymProfile.theme[1]
            : themeConfig.primaryColorLevel
        );
        // &&
        // dispatch(setSettingData(settingsData));
        // const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(
          gymProfile.gym_profile_status
            ? appConfig.mainEntryPath
            : appConfig.welcomeEntryPath
        );
        // return response;
      } else {
        return response;
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors?.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(userLoggedOut());
    dispatch(clearSettingData());
    onThemeColorChange(themeConfig.themeColor);
    onThemeColorLevelChange(themeConfig.primaryColorLevel);
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };
  const checkAuthenticated = () => {
    if (expired > new Date().getTime()) {
      return true;
    } else {
      handleSignOut();
      return false;
    }
  };
  return {
    authenticated: token && signIn && checkAuthenticated(),
    signIn,
    signOut,
  };
}

export default useAuth;
